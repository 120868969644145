@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Noto+Sans:ital@0;1&display=swap");

body {
  /* background: rgb(1,72,113);
    background: linear-gradient(130deg, rgb(95, 90, 72) 0%, rgb(221, 220, 216) 100%); */

  font-family: "Inter", sans-serif;
}

span,
.active {
  @apply bg-gradient-to-b from-[#EDEDE9] to-[#ecebea] bg-clip-text font-bold text-transparent;
}

.bg-primary-color {
  @apply bg-gradient-to-br from-[#dabca5] to-[#9b7057];
}

.bg-secondary-color {
  @apply bg-gradient-to-br from-[#f1e3d9] to-[#92857d];
}

.active {
  @apply font-bold;
}

.gray-primary-color {
  @apply bg-gradient-to-b from-white to-gray-400 bg-clip-text font-bold text-transparent;
}
